body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1440px) {
  .rpv-toolbar .rpv-toolbar__item {
    padding: 0;
  }
}

@media screen and (max-width: 1600px) {
  .rpv-toolbar .rpv-core__minimal-button {
    padding: 0 0.3rem;
  }
}

.rpv-core__display--block-medium:has([aria-describedby="rpv-core__tooltip-body-theme-switch"]),
.rpv-core__display--block-medium:has([aria-describedby="rpv-core__tooltip-body-open"]) {
  display: none;
}
