.pagination-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
}

.rows-per-page-selector,
.page-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.rows-per-page-selector label,
.page-selector label {
  font-size: 0.875rem;
  color: #666;
}

.rows-per-page-selector select,
.page-selector select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 0.875rem;
  min-width: 70px;
  cursor: pointer;
}

.rows-per-page-selector select:hover,
.page-selector select:hover {
  border-color: #999;
}

.rows-per-page-selector select:focus,
.page-selector select:focus {
  outline: none;
  border-color: #666;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}